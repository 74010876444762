.ugc-session-dialog,
.lite-session-dialog,
.pro-session-dialog {
  .mat-dialog-container {
    border-radius: 12px;
    background: #fff;
    padding: 24px;
    box-shadow: 0px 12px 40px 0px rgba(16, 24, 64, 0.1);
  }
}
