.brand-kit-settings-form,
.template-settings-form,
.template-settings-header-form {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding: 0;
    margin-bottom: 16px;

    > .mat-form-field-flex {
      padding: 0;
    }
  }

  openreel-font-picker.ng-invalid {
    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-color: #d63649;
    }
  }

  openreel-color-picker.ng-invalid {
    .wizard-box {
      border: 1px solid #d63649;
    }
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end .mat-form-field-outline-gap {
    background: #ffffff;
  }

  .mat-form-field {
    width: 100%;
    font-size: 16px;
    line-height: 24px;

    .mat-form-field-outline {
      top: 0;
    }

    .mat-form-field-prefix {
      top: 0;
      line-height: 22px;
      padding: 10px 12px;
      border-right: 1px solid #d0d5dd;
      color: #667085;
      font-weight: 400;
    }

    .mat-form-field-infix {
      border: 0;
      padding: 10px 14px;
    }

    &.mat-focused {
      .mat-form-field-prefix {
        line-height: 20px;
      }
    }

    .mat-form-field-flex:hover {
      .mat-form-field-prefix {
        line-height: 20px;
      }
    }
  }

  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      width: 36px;
      height: 20px;
      border-radius: 12px;
    }

    .mat-slide-toggle-thumb-container {
      width: 16px;
      height: 16px;
      top: 0;
      left: 2px;

      .mat-slide-toggle-thumb {
        width: 16px;
        height: 16px;
      }
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: #0066ff !important;
      }

      .mat-slide-toggle-thumb-container {
        transform: translate3d(16px, 0, 0);
      }

      .mat-ripple-element {
        background-color: #0066ff;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }
}
