@import 'inv.scss';
@import 'scrollbar.scss';

.box-shadow {
  box-shadow: 0px 3px 6px -5px #eff2ff, 0px 2px 12px 2px #d1d6ec;
}
.container-item {
  background-color: #fff;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 0 7px #dce0f5;
  .container-title {
    @include font-h-4-rubik-left();
  }
}

.min-height-zero {
  min-height: 0;
}

openreel-hosting-share {
  .mat-button-focus-overlay {
    display: none !important;
  }
}

.vjs-viostream-chaptering-dropdown {
  background: #616161;
  width: 100%;
  font-size: 14px;
  color: white;
}

.text-highlight {
  color: #0066ff;
}
