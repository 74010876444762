/** This file replaces some material design appearance standards **/
@use '@angular/material' as mat;

// progress bar
.mat-progress-bar {
  border-radius: 2px;

  .mat-progress-bar-fill {
    border-radius: 2px;
  }
}

// Expansion panels

.dark-theme {
  .mat-expansion-panel {
    background-color: #262830;

    .mat-expansion-panel-header {
      background: inherit;
      padding: 0 18px;
    }

    .mat-expansion-panel-content {
      background: inherit;
    }
  }
}

/** Forms **/
.mat-form-field-appearance-outline.mat-form-field-type-mat-input {
  .mat-form-field-flex {
    padding: 0 0.75em 0 1.5em;
  }
}

.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 0.75em 0 0.75em 0;
    border-top: 0.64375em solid transparent;
  }
}

.mat-form-field.form-field-no-underline {
  .mat-form-field-flex {
    align-items: center;

    .mat-form-field-infix {
      border-top: 0;
    }
  }
}

.mat-form-field.short-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }

  .mat-form-field-infix {
    padding: 4px 0;
    font-size: 12px;
    font-weight: 300;
    color: #d4d4d4;
  }
}

.mat-form-field.telepromter-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }

  .mat-form-field-infix {
    padding: 8px 0;
    font-weight: 300;
  }
}

.mat-form-field.no-margin-padding {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
  }
}

/** slide toggle **/
.mat-slide-toggle {
  .mat-slide-toggle-thumb-container {
    height: 12px;
    width: 12px;
    top: -1px;
    //  left: -1px;
  }

  .mat-slide-toggle-thumb {
    background-color: #ffffff;
    box-shadow: none;
    margin-top: 2px;
    height: 12px;
    width: 12px;
  }

  .mat-slide-toggle-bar {
    //  border: 1px solid red;
    background-color: #4c5662;
    width: 30px;
    height: 15px;

    .mat-slide-toggle-thumb {
      background-color: #ffffff;
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--mat-slide-toggle--checked--toggle-bar--background-color, #6dd400) !important;
      //  border-color: mat-color(map-get($theme, primary));
    }

    .mat-slide-toggle-thumb {
      background-color: #ffffff !important;
    }
  }
}

/** Toggle Group **/
.mat-button-toggle-group.outline {
  box-shadow: none;

  .mat-button-toggle {
    margin: 0 5px;
  }

  .mat-button-toggle-button {
    //  border: 1px solid;
    // border: 1px solid;
    border-radius: 10px;
  }

  .mat-button-toggle-checked {
    color: mat.get-color-from-palette(map-get($theme, primary));
    background: none;

    .mat-button-toggle-button {
      border-color: mat.get-color-from-palette(map-get($theme, primary));
    }
  }

  //    .mat-button-toggle-group-appearance-standard {
  //    }
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: mat.get-color-from-palette(map-get($theme, primary));
  }
}

/** Tabs **/
mat-tab-group {
  &.filled {
    & > .mat-tab-header {
      border: 2px solid #f6f6f6;
      border-radius: 10px;

      .mat-ink-bar {
        display: none;
      }

      .mat-tab-label {
        height: 36px;

        &.mat-tab-label-active {
          background: mat.get-color-from-palette(map-get($theme, primary));

          .mat-tab-label-content {
            padding: 5px;
            color: white;
          }
        }
      }
    }
  }
}

.mat-card-header-text {
  width: 100%;
  text-align: center;
}

.ng5-slider {
  .ng5-slider-selection {
    background: mat.get-color-from-palette(map-get($theme, primary)) !important;
  }
}

.dark-theme .mat-select-value {
  color: #d4d4d4;
}

.dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #358dff;
}

// Dialog

.dark-theme .mat-dialog-container {
  background-color: #202125;
}

.subjects-chooser-backdrop {
  background-color: rgba(198, 201, 206, 0.5);
}

.subjects-chooser {
  mat-dialog-container {
    background-color: #27272f;
    color: #fff;
    border-radius: 0;
  }
}

.teleprompter-popup {
  min-width: 100px;
  min-height: 200px;
  width: 800px;
  height: 410px;
  margin: auto;
  overflow: hidden;

  .dark-theme & mat-dialog-container {
    background-color: #262830 !important;
  }
}

.teleprompter-resize-popup {
  resize: both;
  overflow: hidden;
  margin: auto;
}

.mat-menu-panel,
.mat-mdc-menu-panel {
  min-height: 30px !important;

  .mat-menu-content,
  .mat-mdc-menu-content {
    &:not(:empty) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .mat-menu-item,
    .mat-mdc-menu-item {
      height: 25px;
      line-height: 25px;
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.dark-theme .mat-menu-panel {
  background-color: #3c4253;
}

// BUTTONS
button[mat-button],
button[mat-raised-button],
button[mat-icon-button],
button[mat-fab],
button[mat-stroked-button],
button[mat-flat-button] {
  line-height: 40px;

  &.mat-button-large {
    line-height: 50px;

    mat-icon {
    }
  }

  &.mat-button-small {
    line-height: 30px;
    font-size: 11.25px;

    .mat-button-wrapper {
      vertical-align: top;
    }

    mat-icon {
      font-size: 14px;
      height: 14px;
      width: 14px;
    }
  }
}

button[mat-stroked-button] {
  line-height: 38px;

  &.mat-button-large {
    line-height: 48px;
  }

  &.mat-button-small {
    line-height: 28px;
  }
}

button[mat-fab] {
  line-height: 38px;

  &.mat-button-large {
  }

  &.mat-button-small {
    height: 30px;
    width: 30px;

    .mat-button-wrapper {
      padding: 0;
    }
  }
}

.margin-top-24px {
  margin-top: 24px;
}

.margin-bottom-64px {
  margin-bottom: 64px;
}
