$or-white: #ffffff;
$or-black: #000000;
/** primary **/
$color-primary-blue: #0066ff;
$color-primary-dark: #262830;
$color-primary-neutral: #474d66;
$color-primary-light: #ffffff;
/** secondary **/
$color-secondary-green: #52bd94;
$color-secondary-yellow: #ffb020;
$color-secondary-red: #d14343;
/** tertiary **/
$color-tertiary-violet: #897ae3;
$color-tertiary-teal: #25cbd6;
$color-tertiary-pink: #ed55c2;
$color-tertiary-orange: #de7548;

$color-text-grey: #474d66;

/** extended primary blue **/
$color-primary-blue-600: #1f3d99;
$color-primary-blue-500: #2952cc;
$color-primary-blue-400: #0066ff;
$color-primary-blue-300: #9db5ff;
$color-primary-blue-200: #d6e0ff;
$color-primary-blue-100: #ebf0ff;
$color-primary-blue-50: #f3f6ff;
$color-primary-blue-A100: $or-white;
$color-primary-blue-A200: #f2f4ff;
$color-primary-blue-A400: #b6bace;
$color-primary-blue-A700: #a6b4ff;

/** extended secondary red **/
$color-secondary-red-900: #b51e1e;
$color-secondary-red-800: #c02c2c;
$color-secondary-red-700: #c63434;
$color-secondary-red-600: #cc3d3d;
$color-secondary-red-500: #d14343;
$color-secondary-red-400: #d85f5f;
$color-secondary-red-300: #df7b7b;
$color-secondary-red-200: #e8a1a1;
$color-secondary-red-100: #f1c7c7;
$color-secondary-red-50: #f9e8e8;
$color-secondary-red-A100: #ffecec;
$color-secondary-red-A200: #ffb9b9;
$color-secondary-red-A400: #ff8686;
$color-secondary-red-A700: #ff6d6d;

$color-secondary-gray-50: #f9fafb;
$color-secondary-gray-200: #eaecf0;
$color-secondary-gray-500: #667085;
$color-secondary-gray-600: #475467;
$color-secondary-gray-700: #344054;

/** extended secondary yellow **/
$color-secondary-yellow-900: #ff870b;
$color-secondary-yellow-800: #ff9713;
$color-secondary-yellow-700: #ffa018;
$color-secondary-yellow-600: #ffa91c;
$color-secondary-yellow-500: #ffb020;
$color-secondary-yellow-400: #ffbc41;
$color-secondary-yellow-300: #ffc863;
$color-secondary-yellow-200: #ffd890;
$color-secondary-yellow-100: #ffe7bc;
$color-secondary-yellow-50: #fff6e4;
$color-secondary-yellow-A100: $or-white;
$color-secondary-yellow-A200: #fffaf6;
$color-secondary-yellow-A400: #ffdfc3;
$color-secondary-yellow-A700: #ffd1a9;

/** extended secondary green **/
$color-secondary-green-600: #317159;
$color-secondary-green-500: #429777;
$color-secondary-green-400: #52bd94;
$color-secondary-green-300: #a3e6cd;
$color-secondary-green-200: #dcf2ea;
$color-secondary-green-100: #eef8f4;
$color-secondary-green-50: #f5fbf8;
$color-secondary-green-A100: #daffed;
$color-secondary-green-A200: #a7ffd4;
$color-secondary-green-A400: #74ffbc;
$color-secondary-green-A700: #5affaf;

/** extended  **/
$color-extended-D700: #27272f;
$color-extended-D600: #262830;
$color-extended-D500: #252837;
$color-extended-D400: #262830;
$color-extended-D300: #2f3142;
$color-extended-D200: #2e384d;
$color-extended-D100: #3c4253;
$color-extended-D75: #828594;
$color-extended-D50: #a7abc1;
$color-extended-D0: #eeeeee;

/** extended  **/
$color-neutral-N900: #2e384d;
$color-neutral-N800: #474d66;
$color-neutral-N700: #696f8c;
$color-neutral-N600: #8f95b2;
$color-neutral-N500: #c1c4d6;
$color-neutral-N400: #d8dae5;
$color-neutral-N300: #3c4253;
$color-neutral-N200: #edeff5;
$color-neutral-N100: #f4f6fa;
$color-neutral-N75: #f9fafc;
$color-neutral-N50: #fafbff;
$color-neutral-N0: #ffffff;

/** FROM inv, should be replaced by the true colors names from Sergiu files **/
$color-toggle-inactive-state: #69707f;
$color-divider-bar: #e6e8f0;

$color-heading: #101828;
$color-sub-heading: #667085;
$color-error-background: #f9dada;
$color-warn-background: #ffefd2;

$color-button-toggle-bg-light: #f4f4f6;

$color-light-3: #8798ad;

/** primary **/
.mat-primary-blue {
  background-color: $color-primary-blue;
}
.mat-primary-dark {
  background-color: $color-primary-dark;
}
.mat-primary-neutral {
  background-color: $color-primary-neutral;
}
.mat-primary-light {
  background-color: $color-primary-light;
}
.mat-primary-blue-font {
  color: $color-primary-blue;
}
.mat-primary-dark-font {
  color: $color-primary-dark;
}
.mat-primary-neutral-font {
  color: $color-primary-neutral;
}
.mat-primary-light-font {
  color: $color-primary-light;
}
.mat-primary-blue-border {
  border-color: $color-primary-blue;
}
.mat-primary-dark-border {
  border-color: $color-primary-dark;
}
.mat-primary-neutral-border {
  border-color: $color-primary-neutral;
}
.mat-primary-light-border {
  border-color: $color-primary-light;
}
/** secondary **/
.mat-secondary-green {
  background-color: $color-secondary-green;
}
.mat-secondary-yellow {
  background-color: $color-secondary-yellow;
}
.mat-secondary-red {
  background-color: $color-secondary-red;
}
.mat-secondary-green-font {
  color: $color-secondary-green;
}
.mat-secondary-yellow-font {
  color: $color-secondary-yellow;
}
.mat-secondary-red-font {
  color: $color-secondary-red;
}
.mat-secondary-green-border {
  border-color: $color-secondary-green;
}
.mat-secondary-yellow-border {
  border-color: $color-secondary-yellow;
}
.mat-secondary-red-border {
  border-color: $color-secondary-red;
}
/** tertiary **/
.mat-tertiary-violet {
  background-color: $color-tertiary-violet;
}
.mat-tertiary-teal {
  background-color: $color-tertiary-teal;
}
.mat-tertiary-pink {
  background-color: $color-tertiary-pink;
}
.mat-tertiary-orange {
  background-color: $color-tertiary-orange;
}
.mat-tertiary-violet-font {
  color: $color-tertiary-violet;
}
.mat-tertiary-teal-font {
  color: $color-tertiary-teal;
}
.mat-tertiary-pink-font {
  color: $color-tertiary-pink;
}
.mat-tertiary-orange-font {
  color: $color-tertiary-orange;
}
.mat-tertiary-violet-border {
  border-color: $color-tertiary-violet;
}
.mat-tertiary-teal-border {
  border-color: $color-tertiary-teal;
}
.mat-tertiary-pink-border {
  border-color: $color-tertiary-pink;
}
.mat-tertiary-orange-border {
  border-color: $color-tertiary-orange;
}
