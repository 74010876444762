
.hosting-container .mat-drawer-inner-container {
    overflow: hidden !important;
}
  
openreel-hosting-dialog-sharing {
    .mat-button-focus-overlay {
        display: none !important;
    }
}

.cdk-overlay-container .cdk-overlay-pane .toolbar-sort{
    font-size: 14px !important;
    font-weight: 500  !important;
}

.hosting-toolbar .mat-checkbox-inner-container {
    background-color: #f4f4f6;

    width: 18px;
    height: 18px;

    border-radius: 6px;
}

.hosting-toolbar .mat-checkbox-inner-container-no-side-margin {
    margin-left: -1px !important;
}

.hosting-toolbar .mat-checkbox-frame{
    border-color: #f4f4f6;
    border-radius: 5px;    
 }

.hosting-toolbar .mat-select-value {
    padding-top: 4px;
    padding-left: 9px;
    font-size: 14px;
}

.hosting-toolbar .mat-select-arrow {
    margin-right: 10px;
    margin-top: 2px;
}

.hosting-navbar .mat-badge-content 
{
    right: -22px !important;
    top: 0px !important;
    background-color: #f4f4f6;
    color:#979caa;
    font-weight: 500;
    font-size: 10px;
}

.hosting-navbar .mat-chip-list-wrapper {
    flex-direction: column;
    align-items: flex-start;
}

.hosting-menu-btn.mat-flat-button.mat-button-base {
    height: 34px !important;
    width: 34px !important;
    line-height: 34px !important;
    padding: 0 !important;
    min-width: 34px !important;
    max-width: 34px !important;
    box-shadow: 2px 2px 10px 3px rgb(0 0 0 / 5%);
}

.hosting-btn.mat-button, 
.hosting-btn.mat-stroked-button {
    height: 34px;
    background-color: #FFF;
    color: #2E384D;
    border-radius: 6px;
    line-height: 25px;
    box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.05);
    &.primary {
        background-color: #0066FF;
        color: #FFF;
    }
    &.mat-button-disabled {
        opacity: .6;
    }
}