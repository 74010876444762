@import 'fonts/rubik';

/** Replace Angular Material Fonts */

.background-grad-image {
  background-image: url('../../assets/images/background.grad.jpg');
  background-size: cover;
}

.background-guest-image {
  background: rgb(2,0,36);
  background-image: url('../../assets/images/bg_guest_image.png'), linear-gradient(45deg, rgba(1,119,255,1) 0%, rgba(53,183,255,1) 100%);
  background-size: cover;
}

