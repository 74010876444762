@import 'video.js/dist/video-js.css';

.hosting-player {
    .vjs-poster {
        //box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    &.no-control-bar {
        .vjs-control-bar {
            display: none;
        }
    }

    &.no-poster {
        .vjs-poster {
            display: none;
        }
    }

    &.edit-mode {
        .vjs-poster {
            display: none;
        }

        //.vjs-control-bar {
        //  display: none;
        //}
        //
        //.vjs-tech {
        //  pointer-events: none;
        //}
        //
        //.vjs-big-play-button {
        //  display: none;
        //}
    }

    .btn-overlay-timeline-container {
        position: absolute;
        height: 30px;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-overlay-timeline {
            width: 95%;
            height: 5px;
            background-color: rgb(255, 255, 255, 0.5);
            border-radius: 10px;
            position: relative;

            .btn-overlay-timeline-marker {
                position: absolute;
                height: 5px;
                background-color: #fff;
                width: 1px;

                &:before {
                    z-index: 2000;
                    position: absolute;
                    bottom: -4px;
                    height: 0;
                    width: 0;
                    left: -7.5px;
                    border: 8px solid transparent;
                    border-top-color: #fff;
                    cursor: pointer;
                    content: "";
                }
            }
        }
    }
}

.video-js.hosting-player,
.video-js.hosting-player:hover {
    // box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .vjs-control-bar {
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 10px 10px;
    }

    .vjs-big-play-button {
        border: none;
        border-radius: 10px;
        font-size: 4em;
    }

    video {
        // box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 10%);
        border-radius: 10px;
    }
}

.video-js.hosting-player {
  container-name: video-player;
  container-type: inline-size;


    // capital for language selection
    .vjs-menu .vjs-menu-content {
        .vjs-menu-item-text {
            text-transform: capitalize;
        }
    }

    // hide 'CC' next to language selection
    .vjs-subs-caps-button+.vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
        display: none;
    }

    // hide Caption Settings
    .vjs-menu .vjs-menu-content {
        .vjs-texttrack-settings {
            display: none;
        }
    }

    .overlay-btn {
        color: #fff;
        position: absolute;
        text-align: center;
        cursor: pointer;
        background: rgba(0, 0, 0, .6);
        border-radius: 3px;
        height: fit-content;
        width: fit-content;
        z-index: 9;
        font-size: 12.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 1% 2%;

        &.editing {
            cursor: grab;

            &.moving {
                cursor: grabbing;
            }
        }

        &.positioned {
            &.top-left {
                top: 8%;
                left: 8%;
            }

            &.top-center {
                top: 8%;
                left: 50%;
                transform: translateX(-50%);
            }

            &.top-right {
                top: 8%;
                right: 8%;
            }

            &.center-left {
                top: 50%;
                left: 8%;
                transform: translateY(-50%);
            }

            &.center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.center-right {
                top: 50%;
                right: 8%;
                transform: translateY(-50%);
            }

            &.bottom-left {
                bottom: 18%;
                left: 8%;
            }

            &.bottom-center {
                bottom: 18%;
                left: 50%;
                transform: translateX(-50%);
            }

            &.bottom-right {
                bottom: 18%;
                right: 8%;
            }
        }
    }

    // fix for the 'Captions Off'
    .vjs-menu li.vjs-selected,
    .vjs-menu li.vjs-selected:focus,
    .vjs-menu li.vjs-selected:hover,
    .js-focus-visible .vjs-menu li.vjs-selected:hover {
        background-color: #fff !important;
    }
}

or-mini-player {

    .video-js.hosting-player,
    .video-js.hosting-player:hover {
        border-radius: 10px 10px 0 0;

        .vjs-control-bar {
            border-radius: 0;
        }

        video {
            border-radius: 10px 10px 0 0;
        }
    }
}

.cornered-player {

    .video-js.hosting-player,
    .video-js.hosting-player:hover {
        border-radius: 0;

        .vjs-control-bar {
            border-radius: 0;
        }

        video {
            border-radius: 0;
        }

        .vjs-poster {
            border-radius: 0;
        }
    }
}

.extract-mode {
    .video-js.hosting-player {
        .vjs-control {
            display: none !important;

            &.vjs-progress-control {
                display: flex !important;
            }
        }

        .vjs-tech {
            pointer-events: none;
        }
    }
}

.video-js.hosting-player {
    .or-watermark-content {
        position: absolute;
        padding: 10px;

        left: 0;
        top: 0;
        opacity: 0.9;
    }

    .or-watermark-content-img {
        width: 30%;
    }
}

.vjs-custom-color {
    .vjs-play-progress::before {
      color: inherit !important;
    }

    .vjs-seekbar-chapter,
    .vjs-seekbar-chapter .vjs-seekbar-chapter-progress-container,
    .vjs-seekbar-chapter .vjs-seekbar-chapter-progress-container .vjs-seekbar-chapter-play-progress {
      background-color: unset !important;
    }
  }

@container video-player (width >= 700px) {
  .overlay-btn {
    font-size: 15px !important;
  }
}

@container video-player (width < 700px) {
  .overlay-btn {
    font-size: 14px !important;
  }
}

@container video-player (width < 650px) {
  .overlay-btn {
    font-size: 13px !important;
  }
}

@container video-player (width < 600px) {
  .overlay-btn {
    font-size: 12px !important;
  }
}

@container video-player (width < 550px) {
  .overlay-btn {
    font-size: 11px !important;
  }
}

@container video-player (width < 500px) {
  .overlay-btn {
    font-size: 10px !important;
  }
}

@container video-player (width < 450px) {
  .overlay-btn {
    font-size: 9px !important;
  }
}

@container video-player (width < 400px) {
  .overlay-btn {
    font-size: 8px !important;
  }
}
