@use 'libs/ui/src/mixins/scrollbar' as scroll;

html {
  --scrollbarBG: #f0f0f0;
  --thumbBG: #cdcdcd;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar {
  width: 7px;
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar {
  width: 7px;
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  // border: 3px solid var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  // border: 3px solid var(--scrollbarBG);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.mat-autocomplete-panel {
  @include scroll.scrollbar();
}
