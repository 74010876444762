.mat-select-panel {
  &.automations-step-header-dropdown {
    .mat-option {
      height: 50px;

      .mat-option-text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .description {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
