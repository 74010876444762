@use './colors.scss' as colors;

/** heading **/
.headline-h900 {
  font-size: 32px;
  line-height: 40px;
}
.headline-h800 {
  font-size: 24px;
  line-height: 32px;
}
.headline-h700 {
  font-size: 20px;
  line-height: 24px;
}
.headline-h600 {
  font-size: 18px;
  line-height: 24px;
}
.headline-h500 {
  font-size: 16px;
  line-height: 24px;
}
.headline-h400 {
  font-size: 14px;
  line-height: 24px;
}
.headline-h300 {
  font-size: 12px;
  line-height: 16px;
}
.headline-h200 {
  font-size: 12px;
  line-height: 16px;
}
.headline-h100 {
  font-size: 10px;
  line-height: 16px;
}

.paragraph-p300 {
  font-size: 16px;
  line-height: 24px;
}
.paragraph-p200 {
  font-size: 14px;
  line-height: 20px;
}
.paragraph-p100 {
  font-size: 12px;
  line-height: 16px;
}

.error-text {
  font-size: 12px;
  color: colors.$color-secondary-red;
  font-family: Roboto, sans-serif;
  display: block;
  line-height: 1.5;
}