/*
    Colors:
*/

// THIS IS DEPRECATED DO NOT ADD ANYTHING HERE, AND DON'T USE IT


$color-primary-brand: #358dff;
$color-primary-brand-2: #2e5bff;
$color-seconday-brand: #35b7ff;
$color-light-2: #bfc5d2;
$color-light-3: #8798ad;
$color-light-4: #8097b1;
$color-primary-brand-dark: #3546ff;
$color-primary-brand-light: #35b7ff;
$color-primary-brand-lighter: #6ac7f9;
$color-seconday-brand: #35b7ff;
$color-white: #ffffff;
$color-white-1: #f5f5f5;
$color-light: #b0bac9;
$color-lightish: #bfc5d2;
$color-blueish-light-grey: #8798ad;
$color-dark: #383838;
$color-dark-1: #3c4253;
$color-blackish: #34343e;
$color-darkish-blackish: #25272f;
$color-dark-blackish: #262830;
$color-darker-blackish: #202125;
$color-active-state-1: #2db744;
$color-active-state-2: #00ba35;
$color-black: #0e111d;
$color-negative-state: #ff6660;
$color-toggle-inactive-state: #69707f;
$color-red: #d63649;
$color-red-1: #de5258;
$color-alice-blue: #e0e7ff;
$color-background-white: #eff2ff;
$color-violet: #897AE3;
$color-dark-2: #27272F;
$color-blueish-dark: #2E384D;
$color-light-5: #EDEFF5;


/*
    Font Styles:
*/

/* H1/Rubik/Left font style */
$font-h-1-rubik-left-font-size: 48px;
$font-h-1-rubik-left-line-height: normal;
$font-h-1-rubik-left-text-align: left;
$font-h-1-rubik-left-color: #2e384d;
$font-h-1-rubik-left-letter-spacing: -0.6px;
$font-h-1-rubik-left-font-style: normal;
$font-h-1-rubik-left-font-weight: 300;
$font-h-1-rubik-left-font-family: Rubik;

@mixin font-h-1-rubik-left() {
  font-size: $font-h-1-rubik-left-font-size;
  line-height: $font-h-1-rubik-left-line-height;
  text-align: $font-h-1-rubik-left-text-align;
  color: $font-h-1-rubik-left-color;
  letter-spacing: $font-h-1-rubik-left-letter-spacing;
  font-style: $font-h-1-rubik-left-font-style;
  font-weight: $font-h-1-rubik-left-font-weight;
  font-family: $font-h-1-rubik-left-font-family;
}

/* H1/Rubik/Center font style */
$font-h-1-rubik-center-font-size: 48px;
$font-h-1-rubik-center-line-height: normal;
$font-h-1-rubik-center-text-align: center;
$font-h-1-rubik-center-color: #2e384d;
$font-h-1-rubik-center-letter-spacing: -0.6px;
$font-h-1-rubik-center-font-style: normal;
$font-h-1-rubik-center-font-weight: 300;
$font-h-1-rubik-center-font-family: Rubik;

@mixin font-h-1-rubik-center() {
  font-size: $font-h-1-rubik-center-font-size;
  line-height: $font-h-1-rubik-center-line-height;
  text-align: $font-h-1-rubik-center-text-align;
  color: $font-h-1-rubik-center-color;
  letter-spacing: $font-h-1-rubik-center-letter-spacing;
  font-style: $font-h-1-rubik-center-font-style;
  font-weight: $font-h-1-rubik-center-font-weight;
  font-family: $font-h-1-rubik-center-font-family;
}

/* H1/Rubik/Right font style */
$font-h-1-rubik-right-font-size: 48px;
$font-h-1-rubik-right-line-height: normal;
$font-h-1-rubik-right-text-align: right;
$font-h-1-rubik-right-color: #2e384d;
$font-h-1-rubik-right-letter-spacing: -0.6px;
$font-h-1-rubik-right-font-style: normal;
$font-h-1-rubik-right-font-weight: 300;
$font-h-1-rubik-right-font-family: Rubik;

@mixin font-h-1-rubik-right() {
  font-size: $font-h-1-rubik-right-font-size;
  line-height: $font-h-1-rubik-right-line-height;
  text-align: $font-h-1-rubik-right-text-align;
  color: $font-h-1-rubik-right-color;
  letter-spacing: $font-h-1-rubik-right-letter-spacing;
  font-style: $font-h-1-rubik-right-font-style;
  font-weight: $font-h-1-rubik-right-font-weight;
  font-family: $font-h-1-rubik-right-font-family;
}

/* H2/Rubik/Left font style */
$font-h-2-rubik-left-font-size: 34px;
$font-h-2-rubik-left-line-height: 32px;
$font-h-2-rubik-left-text-align: left;
$font-h-2-rubik-left-color: #2e384d;
$font-h-2-rubik-left-font-style: normal;
$font-h-2-rubik-left-font-weight: 300;
$font-h-2-rubik-left-font-family: Rubik;

@mixin font-h-2-rubik-left() {
  font-size: $font-h-2-rubik-left-font-size;
  line-height: $font-h-2-rubik-left-line-height;
  text-align: $font-h-2-rubik-left-text-align;
  color: $font-h-2-rubik-left-color;
  font-style: $font-h-2-rubik-left-font-style;
  font-weight: $font-h-2-rubik-left-font-weight;
  font-family: $font-h-2-rubik-left-font-family;
}

/* H2/Rubik/Center font style */
$font-h-2-rubik-center-font-size: 34px;
$font-h-2-rubik-center-line-height: 32px;
$font-h-2-rubik-center-text-align: center;
$font-h-2-rubik-center-color: #2e384d;
$font-h-2-rubik-center-font-style: normal;
$font-h-2-rubik-center-font-weight: 300;
$font-h-2-rubik-center-font-family: Rubik;

@mixin font-h-2-rubik-center() {
  font-size: $font-h-2-rubik-center-font-size;
  line-height: $font-h-2-rubik-center-line-height;
  text-align: $font-h-2-rubik-center-text-align;
  color: $font-h-2-rubik-center-color;
  font-style: $font-h-2-rubik-center-font-style;
  font-weight: $font-h-2-rubik-center-font-weight;
  font-family: $font-h-2-rubik-center-font-family;
}

/* H2/Rubik/Right font style */
$font-h-2-rubik-right-font-size: 34px;
$font-h-2-rubik-right-line-height: 32px;
$font-h-2-rubik-right-text-align: right;
$font-h-2-rubik-right-color: #2e384d;
$font-h-2-rubik-right-font-style: normal;
$font-h-2-rubik-right-font-weight: 300;
$font-h-2-rubik-right-font-family: Rubik;

@mixin font-h-2-rubik-right() {
  font-size: $font-h-2-rubik-right-font-size;
  line-height: $font-h-2-rubik-right-line-height;
  text-align: $font-h-2-rubik-right-text-align;
  color: $font-h-2-rubik-right-color;
  font-style: $font-h-2-rubik-right-font-style;
  font-weight: $font-h-2-rubik-right-font-weight;
  font-family: $font-h-2-rubik-right-font-family;
}

/* H3/Rubik/Left font style */
$font-h-3-rubik-left-font-size: 28px;
$font-h-3-rubik-left-line-height: 32px;
$font-h-3-rubik-left-text-align: left;
$font-h-3-rubik-left-color: #2e384d;
$font-h-3-rubik-left-font-style: normal;
$font-h-3-rubik-left-font-weight: 300;
$font-h-3-rubik-left-font-family: Rubik;

@mixin font-h-3-rubik-left() {
  font-size: $font-h-3-rubik-left-font-size;
  line-height: $font-h-3-rubik-left-line-height;
  text-align: $font-h-3-rubik-left-text-align;
  color: $font-h-3-rubik-left-color;
  font-style: $font-h-3-rubik-left-font-style;
  font-weight: $font-h-3-rubik-left-font-weight;
  font-family: $font-h-3-rubik-left-font-family;
}

/* H3/Rubik/Center font style */
$font-h-3-rubik-center-font-size: 28px;
$font-h-3-rubik-center-line-height: 32px;
$font-h-3-rubik-center-text-align: center;
$font-h-3-rubik-center-color: #2e384d;
$font-h-3-rubik-center-font-style: normal;
$font-h-3-rubik-center-font-weight: 300;
$font-h-3-rubik-center-font-family: Rubik;

@mixin font-h-3-rubik-center() {
  font-size: $font-h-3-rubik-center-font-size;
  line-height: $font-h-3-rubik-center-line-height;
  text-align: $font-h-3-rubik-center-text-align;
  color: $font-h-3-rubik-center-color;
  font-style: $font-h-3-rubik-center-font-style;
  font-weight: $font-h-3-rubik-center-font-weight;
  font-family: $font-h-3-rubik-center-font-family;
}

/* H3/Rubik/Right font style */
$font-h-3-rubik-right-font-size: 28px;
$font-h-3-rubik-right-line-height: 32px;
$font-h-3-rubik-right-text-align: right;
$font-h-3-rubik-right-color: #2e384d;
$font-h-3-rubik-right-font-style: normal;
$font-h-3-rubik-right-font-weight: 300;
$font-h-3-rubik-right-font-family: Rubik;

@mixin font-h-3-rubik-right() {
  font-size: $font-h-3-rubik-right-font-size;
  line-height: $font-h-3-rubik-right-line-height;
  text-align: $font-h-3-rubik-right-text-align;
  color: $font-h-3-rubik-right-color;
  font-style: $font-h-3-rubik-right-font-style;
  font-weight: $font-h-3-rubik-right-font-weight;
  font-family: $font-h-3-rubik-right-font-family;
}

/* H4/Rubik/Left font style */
$font-h-4-rubik-left-font-size: 15px;
$font-h-4-rubik-left-line-height: normal;
$font-h-4-rubik-left-text-align: left;
$font-h-4-rubik-left-color: #2e384d;
$font-h-4-rubik-left-font-style: normal;
$font-h-4-rubik-left-font-weight: 500;
$font-h-4-rubik-left-font-family: Rubik;

@mixin font-h-4-rubik-left() {
  font-size: $font-h-4-rubik-left-font-size;
  line-height: $font-h-4-rubik-left-line-height;
  text-align: $font-h-4-rubik-left-text-align;
  color: $font-h-4-rubik-left-color;
  font-style: $font-h-4-rubik-left-font-style;
  font-weight: $font-h-4-rubik-left-font-weight;
  font-family: $font-h-4-rubik-left-font-family;
}

/* H4/Rubik/Center font style */
$font-h-4-rubik-center-font-size: 15px;
$font-h-4-rubik-center-line-height: normal;
$font-h-4-rubik-center-text-align: center;
$font-h-4-rubik-center-color: #2e384d;
$font-h-4-rubik-center-font-style: normal;
$font-h-4-rubik-center-font-weight: 500;
$font-h-4-rubik-center-font-family: Rubik;

@mixin font-h-4-rubik-center() {
  font-size: $font-h-4-rubik-center-font-size;
  line-height: $font-h-4-rubik-center-line-height;
  text-align: $font-h-4-rubik-center-text-align;
  color: $font-h-4-rubik-center-color;
  font-style: $font-h-4-rubik-center-font-style;
  font-weight: $font-h-4-rubik-center-font-weight;
  font-family: $font-h-4-rubik-center-font-family;
}

/* H4/Rubik/Right font style */
$font-h-4-rubik-right-font-size: 15px;
$font-h-4-rubik-right-line-height: normal;
$font-h-4-rubik-right-text-align: right;
$font-h-4-rubik-right-color: #2e384d;
$font-h-4-rubik-right-font-style: normal;
$font-h-4-rubik-right-font-weight: 500;
$font-h-4-rubik-right-font-family: Rubik;

@mixin font-h-4-rubik-right() {
  font-size: $font-h-4-rubik-right-font-size;
  line-height: $font-h-4-rubik-right-line-height;
  text-align: $font-h-4-rubik-right-text-align;
  color: $font-h-4-rubik-right-color;
  font-style: $font-h-4-rubik-right-font-style;
  font-weight: $font-h-4-rubik-right-font-weight;
  font-family: $font-h-4-rubik-right-font-family;
}

/* Paragraph/Rubik 15pt/Left/Black font style */
$font-paragraph-rubik-15-pt-left-black-font-size: 15px;
$font-paragraph-rubik-15-pt-left-black-line-height: 22px;
$font-paragraph-rubik-15-pt-left-black-text-align: left;
$font-paragraph-rubik-15-pt-left-black-color: #2e384d;
$font-paragraph-rubik-15-pt-left-black-font-style: normal;
$font-paragraph-rubik-15-pt-left-black-font-weight: 400;
$font-paragraph-rubik-15-pt-left-black-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-left-black() {
  font-size: $font-paragraph-rubik-15-pt-left-black-font-size;
  line-height: $font-paragraph-rubik-15-pt-left-black-line-height;
  text-align: $font-paragraph-rubik-15-pt-left-black-text-align;
  color: $font-paragraph-rubik-15-pt-left-black-color;
  font-style: $font-paragraph-rubik-15-pt-left-black-font-style;
  font-weight: $font-paragraph-rubik-15-pt-left-black-font-weight;
  font-family: $font-paragraph-rubik-15-pt-left-black-font-family;
}

/* Paragraph/Rubik 15pt/Left/Gray font style */
$font-paragraph-rubik-15-pt-left-gray-font-size: 15px;
$font-paragraph-rubik-15-pt-left-gray-line-height: 22px;
$font-paragraph-rubik-15-pt-left-gray-text-align: left;
$font-paragraph-rubik-15-pt-left-gray-color: #8798ad;
$font-paragraph-rubik-15-pt-left-gray-font-style: normal;
$font-paragraph-rubik-15-pt-left-gray-font-weight: 400;
$font-paragraph-rubik-15-pt-left-gray-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-left-gray() {
  font-size: $font-paragraph-rubik-15-pt-left-gray-font-size;
  line-height: $font-paragraph-rubik-15-pt-left-gray-line-height;
  text-align: $font-paragraph-rubik-15-pt-left-gray-text-align;
  color: $font-paragraph-rubik-15-pt-left-gray-color;
  font-style: $font-paragraph-rubik-15-pt-left-gray-font-style;
  font-weight: $font-paragraph-rubik-15-pt-left-gray-font-weight;
  font-family: $font-paragraph-rubik-15-pt-left-gray-font-family;
}

/* Paragraph/Rubik 15pt/Left/Gray #2 font style */
$font-paragraph-rubik-15-pt-left-gray-2-font-size: 15px;
$font-paragraph-rubik-15-pt-left-gray-2-line-height: 22px;
$font-paragraph-rubik-15-pt-left-gray-2-text-align: left;
$font-paragraph-rubik-15-pt-left-gray-2-color: #b0bac9;
$font-paragraph-rubik-15-pt-left-gray-2-font-style: normal;
$font-paragraph-rubik-15-pt-left-gray-2-font-weight: 400;
$font-paragraph-rubik-15-pt-left-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-left-gray-2() {
  font-size: $font-paragraph-rubik-15-pt-left-gray-2-font-size;
  line-height: $font-paragraph-rubik-15-pt-left-gray-2-line-height;
  text-align: $font-paragraph-rubik-15-pt-left-gray-2-text-align;
  color: $font-paragraph-rubik-15-pt-left-gray-2-color;
  font-style: $font-paragraph-rubik-15-pt-left-gray-2-font-style;
  font-weight: $font-paragraph-rubik-15-pt-left-gray-2-font-weight;
  font-family: $font-paragraph-rubik-15-pt-left-gray-2-font-family;
}

/* Paragraph/Rubik 15pt/Left/Gray #3 font style */
$font-paragraph-rubik-15-pt-left-gray-2-font-size: 15px;
$font-paragraph-rubik-15-pt-left-gray-2-line-height: 22px;
$font-paragraph-rubik-15-pt-left-gray-2-text-align: left;
$font-paragraph-rubik-15-pt-left-gray-2-color: #bfc5d2;
$font-paragraph-rubik-15-pt-left-gray-2-font-style: normal;
$font-paragraph-rubik-15-pt-left-gray-2-font-weight: 400;
$font-paragraph-rubik-15-pt-left-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-left-gray-3() {
  font-size: $font-paragraph-rubik-15-pt-left-gray-2-font-size;
  line-height: $font-paragraph-rubik-15-pt-left-gray-2-line-height;
  text-align: $font-paragraph-rubik-15-pt-left-gray-2-text-align;
  color: $font-paragraph-rubik-15-pt-left-gray-2-color;
  font-style: $font-paragraph-rubik-15-pt-left-gray-2-font-style;
  font-weight: $font-paragraph-rubik-15-pt-left-gray-2-font-weight;
  font-family: $font-paragraph-rubik-15-pt-left-gray-2-font-family;
}

/* Paragraph/Rubik 15pt/Left/White font style */
$font-paragraph-rubik-15-pt-left-white-font-size: 15px;
$font-paragraph-rubik-15-pt-left-white-line-height: 22px;
$font-paragraph-rubik-15-pt-left-white-text-align: left;
$font-paragraph-rubik-15-pt-left-white-color: #ffffff;
$font-paragraph-rubik-15-pt-left-white-font-style: normal;
$font-paragraph-rubik-15-pt-left-white-font-weight: 400;
$font-paragraph-rubik-15-pt-left-white-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-left-white() {
  font-size: $font-paragraph-rubik-15-pt-left-white-font-size;
  line-height: $font-paragraph-rubik-15-pt-left-white-line-height;
  text-align: $font-paragraph-rubik-15-pt-left-white-text-align;
  color: $font-paragraph-rubik-15-pt-left-white-color;
  font-style: $font-paragraph-rubik-15-pt-left-white-font-style;
  font-weight: $font-paragraph-rubik-15-pt-left-white-font-weight;
  font-family: $font-paragraph-rubik-15-pt-left-white-font-family;
}

/* Paragraph/Rubik 15pt/Center/Black font style */
$font-paragraph-rubik-15-pt-center-black-font-size: 15px;
$font-paragraph-rubik-15-pt-center-black-line-height: 22px;
$font-paragraph-rubik-15-pt-center-black-text-align: center;
$font-paragraph-rubik-15-pt-center-black-color: #2e384d;
$font-paragraph-rubik-15-pt-center-black-font-style: normal;
$font-paragraph-rubik-15-pt-center-black-font-weight: 400;
$font-paragraph-rubik-15-pt-center-black-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-center-black() {
  font-size: $font-paragraph-rubik-15-pt-center-black-font-size;
  line-height: $font-paragraph-rubik-15-pt-center-black-line-height;
  text-align: $font-paragraph-rubik-15-pt-center-black-text-align;
  color: $font-paragraph-rubik-15-pt-center-black-color;
  font-style: $font-paragraph-rubik-15-pt-center-black-font-style;
  font-weight: $font-paragraph-rubik-15-pt-center-black-font-weight;
  font-family: $font-paragraph-rubik-15-pt-center-black-font-family;
}

/* Paragraph/Rubik 15pt/Center/Gray font style */
$font-paragraph-rubik-15-pt-center-gray-font-size: 15px;
$font-paragraph-rubik-15-pt-center-gray-line-height: 22px;
$font-paragraph-rubik-15-pt-center-gray-text-align: center;
$font-paragraph-rubik-15-pt-center-gray-color: #8798ad;
$font-paragraph-rubik-15-pt-center-gray-font-style: normal;
$font-paragraph-rubik-15-pt-center-gray-font-weight: 400;
$font-paragraph-rubik-15-pt-center-gray-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-center-gray() {
  font-size: $font-paragraph-rubik-15-pt-center-gray-font-size;
  line-height: $font-paragraph-rubik-15-pt-center-gray-line-height;
  text-align: $font-paragraph-rubik-15-pt-center-gray-text-align;
  color: $font-paragraph-rubik-15-pt-center-gray-color;
  font-style: $font-paragraph-rubik-15-pt-center-gray-font-style;
  font-weight: $font-paragraph-rubik-15-pt-center-gray-font-weight;
  font-family: $font-paragraph-rubik-15-pt-center-gray-font-family;
}

/* Paragraph/Rubik 15pt/Center/Gray #2 font style */
$font-paragraph-rubik-15-pt-center-gray-2-font-size: 15px;
$font-paragraph-rubik-15-pt-center-gray-2-line-height: 22px;
$font-paragraph-rubik-15-pt-center-gray-2-text-align: center;
$font-paragraph-rubik-15-pt-center-gray-2-color: #b0bac9;
$font-paragraph-rubik-15-pt-center-gray-2-font-style: normal;
$font-paragraph-rubik-15-pt-center-gray-2-font-weight: 400;
$font-paragraph-rubik-15-pt-center-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-center-gray-2() {
  font-size: $font-paragraph-rubik-15-pt-center-gray-2-font-size;
  line-height: $font-paragraph-rubik-15-pt-center-gray-2-line-height;
  text-align: $font-paragraph-rubik-15-pt-center-gray-2-text-align;
  color: $font-paragraph-rubik-15-pt-center-gray-2-color;
  font-style: $font-paragraph-rubik-15-pt-center-gray-2-font-style;
  font-weight: $font-paragraph-rubik-15-pt-center-gray-2-font-weight;
  font-family: $font-paragraph-rubik-15-pt-center-gray-2-font-family;
}

/* Paragraph/Rubik 15pt/Center/White font style */
$font-paragraph-rubik-15-pt-center-white-font-size: 15px;
$font-paragraph-rubik-15-pt-center-white-line-height: 22px;
$font-paragraph-rubik-15-pt-center-white-text-align: center;
$font-paragraph-rubik-15-pt-center-white-color: #ffffff;
$font-paragraph-rubik-15-pt-center-white-font-style: normal;
$font-paragraph-rubik-15-pt-center-white-font-weight: 400;
$font-paragraph-rubik-15-pt-center-white-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-center-white() {
  font-size: $font-paragraph-rubik-15-pt-center-white-font-size;
  line-height: $font-paragraph-rubik-15-pt-center-white-line-height;
  text-align: $font-paragraph-rubik-15-pt-center-white-text-align;
  color: $font-paragraph-rubik-15-pt-center-white-color;
  font-style: $font-paragraph-rubik-15-pt-center-white-font-style;
  font-weight: $font-paragraph-rubik-15-pt-center-white-font-weight;
  font-family: $font-paragraph-rubik-15-pt-center-white-font-family;
}

/* Paragraph/Rubik 15pt/Right/Black font style */
$font-paragraph-rubik-15-pt-right-black-font-size: 15px;
$font-paragraph-rubik-15-pt-right-black-line-height: 22px;
$font-paragraph-rubik-15-pt-right-black-text-align: right;
$font-paragraph-rubik-15-pt-right-black-color: #2e384d;
$font-paragraph-rubik-15-pt-right-black-font-style: normal;
$font-paragraph-rubik-15-pt-right-black-font-weight: 400;
$font-paragraph-rubik-15-pt-right-black-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-right-black() {
  font-size: $font-paragraph-rubik-15-pt-right-black-font-size;
  line-height: $font-paragraph-rubik-15-pt-right-black-line-height;
  text-align: $font-paragraph-rubik-15-pt-right-black-text-align;
  color: $font-paragraph-rubik-15-pt-right-black-color;
  font-style: $font-paragraph-rubik-15-pt-right-black-font-style;
  font-weight: $font-paragraph-rubik-15-pt-right-black-font-weight;
  font-family: $font-paragraph-rubik-15-pt-right-black-font-family;
}

/* Paragraph/Rubik 15pt/Right/Gray font style */
$font-paragraph-rubik-15-pt-right-gray-font-size: 15px;
$font-paragraph-rubik-15-pt-right-gray-line-height: 22px;
$font-paragraph-rubik-15-pt-right-gray-text-align: right;
$font-paragraph-rubik-15-pt-right-gray-color: #8798ad;
$font-paragraph-rubik-15-pt-right-gray-font-style: normal;
$font-paragraph-rubik-15-pt-right-gray-font-weight: 400;
$font-paragraph-rubik-15-pt-right-gray-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-right-gray() {
  font-size: $font-paragraph-rubik-15-pt-right-gray-font-size;
  line-height: $font-paragraph-rubik-15-pt-right-gray-line-height;
  text-align: $font-paragraph-rubik-15-pt-right-gray-text-align;
  color: $font-paragraph-rubik-15-pt-right-gray-color;
  font-style: $font-paragraph-rubik-15-pt-right-gray-font-style;
  font-weight: $font-paragraph-rubik-15-pt-right-gray-font-weight;
  font-family: $font-paragraph-rubik-15-pt-right-gray-font-family;
}

/* Paragraph/Rubik 15pt/Right/Gray #2 font style */
$font-paragraph-rubik-15-pt-right-gray-2-font-size: 15px;
$font-paragraph-rubik-15-pt-right-gray-2-line-height: 22px;
$font-paragraph-rubik-15-pt-right-gray-2-text-align: right;
$font-paragraph-rubik-15-pt-right-gray-2-color: #b0bac9;
$font-paragraph-rubik-15-pt-right-gray-2-font-style: normal;
$font-paragraph-rubik-15-pt-right-gray-2-font-weight: 400;
$font-paragraph-rubik-15-pt-right-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-right-gray-2() {
  font-size: $font-paragraph-rubik-15-pt-right-gray-2-font-size;
  line-height: $font-paragraph-rubik-15-pt-right-gray-2-line-height;
  text-align: $font-paragraph-rubik-15-pt-right-gray-2-text-align;
  color: $font-paragraph-rubik-15-pt-right-gray-2-color;
  font-style: $font-paragraph-rubik-15-pt-right-gray-2-font-style;
  font-weight: $font-paragraph-rubik-15-pt-right-gray-2-font-weight;
  font-family: $font-paragraph-rubik-15-pt-right-gray-2-font-family;
}

/* Paragraph/Rubik 15pt/Right/White font style */
$font-paragraph-rubik-15-pt-right-white-font-size: 15px;
$font-paragraph-rubik-15-pt-right-white-line-height: 22px;
$font-paragraph-rubik-15-pt-right-white-text-align: right;
$font-paragraph-rubik-15-pt-right-white-color: #ffffff;
$font-paragraph-rubik-15-pt-right-white-font-style: normal;
$font-paragraph-rubik-15-pt-right-white-font-weight: 400;
$font-paragraph-rubik-15-pt-right-white-font-family: Rubik;

@mixin font-paragraph-rubik-15-pt-right-white() {
  font-size: $font-paragraph-rubik-15-pt-right-white-font-size;
  line-height: $font-paragraph-rubik-15-pt-right-white-line-height;
  text-align: $font-paragraph-rubik-15-pt-right-white-text-align;
  color: $font-paragraph-rubik-15-pt-right-white-color;
  font-style: $font-paragraph-rubik-15-pt-right-white-font-style;
  font-weight: $font-paragraph-rubik-15-pt-right-white-font-weight;
  font-family: $font-paragraph-rubik-15-pt-right-white-font-family;
}

/* Paragraph/Rubik 12pt/Left/Black font style */
$font-paragraph-rubik-12-pt-left-black-font-size: 12px;
$font-paragraph-rubik-12-pt-left-black-line-height: 18px;
$font-paragraph-rubik-12-pt-left-black-text-align: left;
$font-paragraph-rubik-12-pt-left-black-color: #2e384d;
$font-paragraph-rubik-12-pt-left-black-font-style: normal;
$font-paragraph-rubik-12-pt-left-black-font-weight: 400;
$font-paragraph-rubik-12-pt-left-black-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-left-black() {
  font-size: $font-paragraph-rubik-12-pt-left-black-font-size;
  line-height: $font-paragraph-rubik-12-pt-left-black-line-height;
  text-align: $font-paragraph-rubik-12-pt-left-black-text-align;
  color: $font-paragraph-rubik-12-pt-left-black-color;
  font-style: $font-paragraph-rubik-12-pt-left-black-font-style;
  font-weight: $font-paragraph-rubik-12-pt-left-black-font-weight;
  font-family: $font-paragraph-rubik-12-pt-left-black-font-family;
}

/* Paragraph/Rubik 12pt/Left/Gray font style */
$font-paragraph-rubik-12-pt-left-gray-font-size: 12px;
$font-paragraph-rubik-12-pt-left-gray-line-height: 18px;
$font-paragraph-rubik-12-pt-left-gray-text-align: left;
$font-paragraph-rubik-12-pt-left-gray-color: #8798ad;
$font-paragraph-rubik-12-pt-left-gray-font-style: normal;
$font-paragraph-rubik-12-pt-left-gray-font-weight: 400;
$font-paragraph-rubik-12-pt-left-gray-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-left-gray() {
  font-size: $font-paragraph-rubik-12-pt-left-gray-font-size;
  line-height: $font-paragraph-rubik-12-pt-left-gray-line-height;
  text-align: $font-paragraph-rubik-12-pt-left-gray-text-align;
  color: $font-paragraph-rubik-12-pt-left-gray-color;
  font-style: $font-paragraph-rubik-12-pt-left-gray-font-style;
  font-weight: $font-paragraph-rubik-12-pt-left-gray-font-weight;
  font-family: $font-paragraph-rubik-12-pt-left-gray-font-family;
}

/* Paragraph/Rubik 12pt/Left/Gray #2 font style */
$font-paragraph-rubik-12-pt-left-gray-2-font-size: 12px;
$font-paragraph-rubik-12-pt-left-gray-2-line-height: 18px;
$font-paragraph-rubik-12-pt-left-gray-2-text-align: left;
$font-paragraph-rubik-12-pt-left-gray-2-color: #b0bac9;
$font-paragraph-rubik-12-pt-left-gray-2-font-style: normal;
$font-paragraph-rubik-12-pt-left-gray-2-font-weight: 400;
$font-paragraph-rubik-12-pt-left-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-left-gray-2() {
  font-size: $font-paragraph-rubik-12-pt-left-gray-2-font-size;
  line-height: $font-paragraph-rubik-12-pt-left-gray-2-line-height;
  text-align: $font-paragraph-rubik-12-pt-left-gray-2-text-align;
  color: $font-paragraph-rubik-12-pt-left-gray-2-color;
  font-style: $font-paragraph-rubik-12-pt-left-gray-2-font-style;
  font-weight: $font-paragraph-rubik-12-pt-left-gray-2-font-weight;
  font-family: $font-paragraph-rubik-12-pt-left-gray-2-font-family;
}

/* Paragraph/Rubik 12pt/Left/White font style */
$font-paragraph-rubik-12-pt-left-white-font-size: 12px;
$font-paragraph-rubik-12-pt-left-white-line-height: 18px;
$font-paragraph-rubik-12-pt-left-white-text-align: left;
$font-paragraph-rubik-12-pt-left-white-color: #ffffff;
$font-paragraph-rubik-12-pt-left-white-font-style: normal;
$font-paragraph-rubik-12-pt-left-white-font-weight: 400;
$font-paragraph-rubik-12-pt-left-white-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-left-white() {
  font-size: $font-paragraph-rubik-12-pt-left-white-font-size;
  line-height: $font-paragraph-rubik-12-pt-left-white-line-height;
  text-align: $font-paragraph-rubik-12-pt-left-white-text-align;
  color: $font-paragraph-rubik-12-pt-left-white-color;
  font-style: $font-paragraph-rubik-12-pt-left-white-font-style;
  font-weight: $font-paragraph-rubik-12-pt-left-white-font-weight;
  font-family: $font-paragraph-rubik-12-pt-left-white-font-family;
}

/* Paragraph/Rubik 12pt/Center/Black font style */
$font-paragraph-rubik-12-pt-center-black-font-size: 12px;
$font-paragraph-rubik-12-pt-center-black-line-height: 22px;
$font-paragraph-rubik-12-pt-center-black-text-align: center;
$font-paragraph-rubik-12-pt-center-black-color: #2e384d;
$font-paragraph-rubik-12-pt-center-black-font-style: normal;
$font-paragraph-rubik-12-pt-center-black-font-weight: 400;
$font-paragraph-rubik-12-pt-center-black-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-center-black($important: false) {
  font-size: $font-paragraph-rubik-12-pt-center-black-font-size if-important($important);
  line-height: $font-paragraph-rubik-12-pt-center-black-line-height if-important($important);
  text-align: $font-paragraph-rubik-12-pt-center-black-text-align if-important($important);
  color: $font-paragraph-rubik-12-pt-center-black-color if-important($important);
  font-style: $font-paragraph-rubik-12-pt-center-black-font-style if-important($important);
  font-weight: $font-paragraph-rubik-12-pt-center-black-font-weight if-important($important);
  font-family: $font-paragraph-rubik-12-pt-center-black-font-family if-important($important);
}

/* Paragraph/Rubik 12pt/Center/Gray font style */
$font-paragraph-rubik-12-pt-center-gray-font-size: 12px;
$font-paragraph-rubik-12-pt-center-gray-line-height: 22px;
$font-paragraph-rubik-12-pt-center-gray-text-align: center;
$font-paragraph-rubik-12-pt-center-gray-color: #8798ad;
$font-paragraph-rubik-12-pt-center-gray-font-style: normal;
$font-paragraph-rubik-12-pt-center-gray-font-weight: 400;
$font-paragraph-rubik-12-pt-center-gray-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-center-gray() {
  font-size: $font-paragraph-rubik-12-pt-center-gray-font-size;
  line-height: $font-paragraph-rubik-12-pt-center-gray-line-height;
  text-align: $font-paragraph-rubik-12-pt-center-gray-text-align;
  color: $font-paragraph-rubik-12-pt-center-gray-color;
  font-style: $font-paragraph-rubik-12-pt-center-gray-font-style;
  font-weight: $font-paragraph-rubik-12-pt-center-gray-font-weight;
  font-family: $font-paragraph-rubik-12-pt-center-gray-font-family;
}

/* Paragraph/Rubik 12pt/Center/Gray #2 font style */
$font-paragraph-rubik-12-pt-center-gray-2-font-size: 12px;
$font-paragraph-rubik-12-pt-center-gray-2-line-height: 22px;
$font-paragraph-rubik-12-pt-center-gray-2-text-align: center;
$font-paragraph-rubik-12-pt-center-gray-2-color: #b0bac9;
$font-paragraph-rubik-12-pt-center-gray-2-font-style: normal;
$font-paragraph-rubik-12-pt-center-gray-2-font-weight: 400;
$font-paragraph-rubik-12-pt-center-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-center-gray-2() {
  font-size: $font-paragraph-rubik-12-pt-center-gray-2-font-size;
  line-height: $font-paragraph-rubik-12-pt-center-gray-2-line-height;
  text-align: $font-paragraph-rubik-12-pt-center-gray-2-text-align;
  color: $font-paragraph-rubik-12-pt-center-gray-2-color;
  font-style: $font-paragraph-rubik-12-pt-center-gray-2-font-style;
  font-weight: $font-paragraph-rubik-12-pt-center-gray-2-font-weight;
  font-family: $font-paragraph-rubik-12-pt-center-gray-2-font-family;
}

/* Paragraph/Rubik 12pt/Center/White font style */
$font-paragraph-rubik-12-pt-center-white-font-size: 12px;
$font-paragraph-rubik-12-pt-center-white-line-height: 22px;
$font-paragraph-rubik-12-pt-center-white-text-align: center;
$font-paragraph-rubik-12-pt-center-white-color: #ffffff;
$font-paragraph-rubik-12-pt-center-white-font-style: normal;
$font-paragraph-rubik-12-pt-center-white-font-weight: 400;
$font-paragraph-rubik-12-pt-center-white-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-center-white() {
  font-size: $font-paragraph-rubik-12-pt-center-white-font-size;
  line-height: $font-paragraph-rubik-12-pt-center-white-line-height;
  text-align: $font-paragraph-rubik-12-pt-center-white-text-align;
  color: $font-paragraph-rubik-12-pt-center-white-color;
  font-style: $font-paragraph-rubik-12-pt-center-white-font-style;
  font-weight: $font-paragraph-rubik-12-pt-center-white-font-weight;
  font-family: $font-paragraph-rubik-12-pt-center-white-font-family;
}

/* Paragraph/Rubik 12pt/Right/Black font style */
$font-paragraph-rubik-12-pt-right-black-font-size: 12px;
$font-paragraph-rubik-12-pt-right-black-line-height: 18px;
$font-paragraph-rubik-12-pt-right-black-text-align: right;
$font-paragraph-rubik-12-pt-right-black-color: #2e384d;
$font-paragraph-rubik-12-pt-right-black-font-style: normal;
$font-paragraph-rubik-12-pt-right-black-font-weight: 400;
$font-paragraph-rubik-12-pt-right-black-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-right-black() {
  font-size: $font-paragraph-rubik-12-pt-right-black-font-size;
  line-height: $font-paragraph-rubik-12-pt-right-black-line-height;
  text-align: $font-paragraph-rubik-12-pt-right-black-text-align;
  color: $font-paragraph-rubik-12-pt-right-black-color;
  font-style: $font-paragraph-rubik-12-pt-right-black-font-style;
  font-weight: $font-paragraph-rubik-12-pt-right-black-font-weight;
  font-family: $font-paragraph-rubik-12-pt-right-black-font-family;
}

/* Paragraph/Rubik 12pt/Right/Gray font style */
$font-paragraph-rubik-12-pt-right-gray-font-size: 12px;
$font-paragraph-rubik-12-pt-right-gray-line-height: 18px;
$font-paragraph-rubik-12-pt-right-gray-text-align: right;
$font-paragraph-rubik-12-pt-right-gray-color: #8798ad;
$font-paragraph-rubik-12-pt-right-gray-font-style: normal;
$font-paragraph-rubik-12-pt-right-gray-font-weight: 400;
$font-paragraph-rubik-12-pt-right-gray-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-right-gray() {
  font-size: $font-paragraph-rubik-12-pt-right-gray-font-size;
  line-height: $font-paragraph-rubik-12-pt-right-gray-line-height;
  text-align: $font-paragraph-rubik-12-pt-right-gray-text-align;
  color: $font-paragraph-rubik-12-pt-right-gray-color;
  font-style: $font-paragraph-rubik-12-pt-right-gray-font-style;
  font-weight: $font-paragraph-rubik-12-pt-right-gray-font-weight;
  font-family: $font-paragraph-rubik-12-pt-right-gray-font-family;
}

/* Paragraph/Rubik 12pt/Right/Gray #2 font style */
$font-paragraph-rubik-12-pt-right-gray-2-font-size: 12px;
$font-paragraph-rubik-12-pt-right-gray-2-line-height: 18px;
$font-paragraph-rubik-12-pt-right-gray-2-text-align: right;
$font-paragraph-rubik-12-pt-right-gray-2-color: #b0bac9;
$font-paragraph-rubik-12-pt-right-gray-2-font-style: normal;
$font-paragraph-rubik-12-pt-right-gray-2-font-weight: 400;
$font-paragraph-rubik-12-pt-right-gray-2-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-right-gray-2() {
  font-size: $font-paragraph-rubik-12-pt-right-gray-2-font-size;
  line-height: $font-paragraph-rubik-12-pt-right-gray-2-line-height;
  text-align: $font-paragraph-rubik-12-pt-right-gray-2-text-align;
  color: $font-paragraph-rubik-12-pt-right-gray-2-color;
  font-style: $font-paragraph-rubik-12-pt-right-gray-2-font-style;
  font-weight: $font-paragraph-rubik-12-pt-right-gray-2-font-weight;
  font-family: $font-paragraph-rubik-12-pt-right-gray-2-font-family;
}

/* Paragraph/Rubik 12pt/Right/White font style */
$font-paragraph-rubik-12-pt-right-white-font-size: 12px;
$font-paragraph-rubik-12-pt-right-white-line-height: 18px;
$font-paragraph-rubik-12-pt-right-white-text-align: right;
$font-paragraph-rubik-12-pt-right-white-color: #ffffff;
$font-paragraph-rubik-12-pt-right-white-font-style: normal;
$font-paragraph-rubik-12-pt-right-white-font-weight: 400;
$font-paragraph-rubik-12-pt-right-white-font-family: Rubik;

@mixin font-paragraph-rubik-12-pt-right-white() {
  font-size: $font-paragraph-rubik-12-pt-right-white-font-size;
  line-height: $font-paragraph-rubik-12-pt-right-white-line-height;
  text-align: $font-paragraph-rubik-12-pt-right-white-text-align;
  color: $font-paragraph-rubik-12-pt-right-white-color;
  font-style: $font-paragraph-rubik-12-pt-right-white-font-style;
  font-weight: $font-paragraph-rubik-12-pt-right-white-font-weight;
  font-family: $font-paragraph-rubik-12-pt-right-white-font-family;
}

/* Caption/Rubik/Center/Gray font style */
$font-caption-rubik-center-gray-font-size: 12px;
$font-caption-rubik-center-gray-line-height: normal;
$font-caption-rubik-center-gray-text-align: center;
$font-caption-rubik-center-gray-color: #b0bac9;
$font-caption-rubik-center-gray-letter-spacing: 1.13px;
$font-caption-rubik-center-gray-text-transform: uppercase;
$font-caption-rubik-center-gray-font-style: normal;
$font-caption-rubik-center-gray-font-weight: 500;
$font-caption-rubik-center-gray-font-family: Rubik;

@mixin font-caption-rubik-center-gray() {
  font-size: $font-caption-rubik-center-gray-font-size;
  line-height: $font-caption-rubik-center-gray-line-height;
  text-align: $font-caption-rubik-center-gray-text-align;
  color: $font-caption-rubik-center-gray-color;
  letter-spacing: $font-caption-rubik-center-gray-letter-spacing;
  text-transform: $font-caption-rubik-center-gray-text-transform;
  font-style: $font-caption-rubik-center-gray-font-style;
  font-weight: $font-caption-rubik-center-gray-font-weight;
  font-family: $font-caption-rubik-center-gray-font-family;
}

/* Caption/Rubik/Left/Gray font style */
$font-caption-rubik-left-gray-font-size: 12px;
$font-caption-rubik-left-gray-line-height: normal;
$font-caption-rubik-left-gray-text-align: left;
$font-caption-rubik-left-gray-color: #b0bac9;
$font-caption-rubik-left-gray-letter-spacing: 1.13px;
$font-caption-rubik-left-gray-text-transform: uppercase;
$font-caption-rubik-left-gray-font-style: normal;
$font-caption-rubik-left-gray-font-weight: 500;
$font-caption-rubik-left-gray-font-family: Rubik;

@mixin font-caption-rubik-left-gray() {
  font-size: $font-caption-rubik-left-gray-font-size;
  line-height: $font-caption-rubik-left-gray-line-height;
  text-align: $font-caption-rubik-left-gray-text-align;
  color: $font-caption-rubik-left-gray-color;
  letter-spacing: $font-caption-rubik-left-gray-letter-spacing;
  text-transform: $font-caption-rubik-left-gray-text-transform;
  font-style: $font-caption-rubik-left-gray-font-style;
  font-weight: $font-caption-rubik-left-gray-font-weight;
  font-family: $font-caption-rubik-left-gray-font-family;
}

/*
    Utilities:
*/

@function if-important($important) {
  @return #{if($important, '!important', '')};
}