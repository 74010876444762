@import '../styles/colors';

@mixin scrollbar($direction: vertical, $width: 3px) {
  @if ($direction == vertical) {
    overflow-y: auto;
  }

  @if ($direction == horizontal) {
    overflow-x: auto;
  }

  @if ($direction == both) {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-neutral-N400;
    box-shadow: inset 0 0 $width $color-neutral-N400;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-neutral-N600;
    border-radius: 5px;
  }
}
