@import '../colors.scss';

.mat-button-toggle-group.pill-toggle {
  background-color: $color-neutral-N100;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #eaecf0;

  .mat-button-toggle {
    background-color: $color-neutral-N100;
    overflow: hidden;
    padding: 4px;
    flex: 1;

    .mat-button-toggle-button {
      background-color: $color-neutral-N100;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;
      color: #667085;
      border: 1px solid transparent;
    }

    &.has-error {
      .mat-button-toggle-button {
        color: $color-secondary-red;
      }
    }
  }

  .mat-button-toggle-checked {
    background-color: $color-neutral-N100;

    .mat-button-toggle-button {
      background-color: #fff;
      box-shadow: rgba(16, 24, 40, 0.1) 0px 3px 3px;
      color: $color-primary-blue;

      .mat-button-toggle-label-content mat-icon {
        color: $color-primary-blue;
      }
    }

    &:hover {
      .mat-button-toggle-focus-overlay {
        opacity: 0 !important;
      }
    }
  }

  .mat-button-toggle + .mat-button-toggle {
    border-left: none;
  }

  .mat-button-toggle-label-content {
    line-height: 36px;
    height: 36px;
    padding: 0;
  }
}
