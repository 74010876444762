/* You can add global styles to this file, and also import other style files */
// @import "./assets/css/material-icons.css";
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import 'swiper/css/swiper.min.css';
@import 'sweetalert2/src/sweetalert2.scss';
@import './video-preview.scss';
@import 'libs/ui/styles/styles.scss';
@import './hosting.scss';
@import './settings/settings.scss';
@import './vendor/import';
@import './components/dialog.scss';
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
  font-size: min(max(1rem, 4vw), 22px);

  .swal2-popup {
    font-size: 100%;
  }
}

html,
body,
.full-height {
  height: 100%;
}

.content-holder {
  width: 400px;
}

.whitebg {
  background: #fff;
}

.slim-scroll::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
  background-color: #2e384d;
}

.slim-scroll::-webkit-scrollbar-thumb {
  background-color: #8798ad;
  border-radius: 2px;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  // opacity: 0.8;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  // filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
/* use gu-transit-target class to specify which child element should receive dashed affect
    and whose children should be invisible */
.gu-transit .gu-transit-target {
  border: 1px dashed #fff;
  // opacity: 0.2;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  // filter: alpha(opacity=20);
}

.gu-transit .gu-transit-target * {
  visibility: hidden;
}

.ignore-mouse {
  pointer-events: none;
}

.mat-icon {
  vertical-align: middle;
}

.subject-teleprompter {
  table {
    td {
      border: 1px solid #ddd !important;
    }
  }
}

.page-content-container {
  box-sizing: border-box;

  @media screen and (min-width: 1025px) {
    padding: 25px 80px 25px 160px !important;
  }

  @media (min-width: 415px) and (max-width: 1024px) {
    padding: 20px 48px 25px 120px !important;
  }

  @media screen and (max-width: 414px) {
    padding: 20px 10px 25px 88px !important;
  }
}

// fix froala teleprompter issue

p[data-f-id='pbf'] {
  display: none;
}

.cdk-overlay-container .cdk-overlay-pane .user-update-country-phone-code {
  overflow-x: hidden;
}

// fix accessbi icon
.acsb-trigger {
  width: 56px !important;
  height: 56px !important;
  right: 70px !important;
  bottom: 10px !important;
}

.w-full {
  width: 100%;
}
