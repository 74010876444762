/*
    Colors:
*/
/*
    Font Styles:
*/
/* H1/Rubik/Left font style */
/* H1/Rubik/Center font style */
/* H1/Rubik/Right font style */
/* H2/Rubik/Left font style */
/* H2/Rubik/Center font style */
/* H2/Rubik/Right font style */
/* H3/Rubik/Left font style */
/* H3/Rubik/Center font style */
/* H3/Rubik/Right font style */
/* H4/Rubik/Left font style */
/* H4/Rubik/Center font style */
/* H4/Rubik/Right font style */
/* Paragraph/Rubik 15pt/Left/Black font style */
/* Paragraph/Rubik 15pt/Left/Gray font style */
/* Paragraph/Rubik 15pt/Left/Gray #2 font style */
/* Paragraph/Rubik 15pt/Left/Gray #3 font style */
/* Paragraph/Rubik 15pt/Left/White font style */
/* Paragraph/Rubik 15pt/Center/Black font style */
/* Paragraph/Rubik 15pt/Center/Gray font style */
/* Paragraph/Rubik 15pt/Center/Gray #2 font style */
/* Paragraph/Rubik 15pt/Center/White font style */
/* Paragraph/Rubik 15pt/Right/Black font style */
/* Paragraph/Rubik 15pt/Right/Gray font style */
/* Paragraph/Rubik 15pt/Right/Gray #2 font style */
/* Paragraph/Rubik 15pt/Right/White font style */
/* Paragraph/Rubik 12pt/Left/Black font style */
/* Paragraph/Rubik 12pt/Left/Gray font style */
/* Paragraph/Rubik 12pt/Left/Gray #2 font style */
/* Paragraph/Rubik 12pt/Left/White font style */
/* Paragraph/Rubik 12pt/Center/Black font style */
/* Paragraph/Rubik 12pt/Center/Gray font style */
/* Paragraph/Rubik 12pt/Center/Gray #2 font style */
/* Paragraph/Rubik 12pt/Center/White font style */
/* Paragraph/Rubik 12pt/Right/Black font style */
/* Paragraph/Rubik 12pt/Right/Gray font style */
/* Paragraph/Rubik 12pt/Right/Gray #2 font style */
/* Paragraph/Rubik 12pt/Right/White font style */
/* Caption/Rubik/Center/Gray font style */
/* Caption/Rubik/Left/Gray font style */
/*
    Utilities:
*/
/* cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), local("Rubik"), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), local("Rubik"), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), local("Rubik"), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Fqj2mZ8WD07oB-98o.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Fqj2mf8WD07oB-98o.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Fqj2mT8WD07oB-98o.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Rubik Light"), local("Rubik-Light"), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Fqj2md8WD07oB-.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFWkUzdYPFkZVOA6w.woff) format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFU0UzdYPFkZVOA6w.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFVUUzdYPFkZVOA6w.woff) format("woff");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFWUUzdYPFkZVOA6w.woff) format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFV0UzdYPFkZVO.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/** Replace Angular Material Fonts */
.background-grad-image {
  background-image: url("../../assets/images/background.grad.jpg");
  background-size: cover;
}

.background-guest-image {
  background: rgb(2, 0, 36);
  background-image: url("../../assets/images/bg_guest_image.png"), linear-gradient(45deg, rgb(1, 119, 255) 0%, rgb(53, 183, 255) 100%);
  background-size: cover;
}

/*
    Colors:
*/
/*
    Font Styles:
*/
/* H1/Rubik/Left font style */
/* H1/Rubik/Center font style */
/* H1/Rubik/Right font style */
/* H2/Rubik/Left font style */
/* H2/Rubik/Center font style */
/* H2/Rubik/Right font style */
/* H3/Rubik/Left font style */
/* H3/Rubik/Center font style */
/* H3/Rubik/Right font style */
/* H4/Rubik/Left font style */
/* H4/Rubik/Center font style */
/* H4/Rubik/Right font style */
/* Paragraph/Rubik 15pt/Left/Black font style */
/* Paragraph/Rubik 15pt/Left/Gray font style */
/* Paragraph/Rubik 15pt/Left/Gray #2 font style */
/* Paragraph/Rubik 15pt/Left/Gray #3 font style */
/* Paragraph/Rubik 15pt/Left/White font style */
/* Paragraph/Rubik 15pt/Center/Black font style */
/* Paragraph/Rubik 15pt/Center/Gray font style */
/* Paragraph/Rubik 15pt/Center/Gray #2 font style */
/* Paragraph/Rubik 15pt/Center/White font style */
/* Paragraph/Rubik 15pt/Right/Black font style */
/* Paragraph/Rubik 15pt/Right/Gray font style */
/* Paragraph/Rubik 15pt/Right/Gray #2 font style */
/* Paragraph/Rubik 15pt/Right/White font style */
/* Paragraph/Rubik 12pt/Left/Black font style */
/* Paragraph/Rubik 12pt/Left/Gray font style */
/* Paragraph/Rubik 12pt/Left/Gray #2 font style */
/* Paragraph/Rubik 12pt/Left/White font style */
/* Paragraph/Rubik 12pt/Center/Black font style */
/* Paragraph/Rubik 12pt/Center/Gray font style */
/* Paragraph/Rubik 12pt/Center/Gray #2 font style */
/* Paragraph/Rubik 12pt/Center/White font style */
/* Paragraph/Rubik 12pt/Right/Black font style */
/* Paragraph/Rubik 12pt/Right/Gray font style */
/* Paragraph/Rubik 12pt/Right/Gray #2 font style */
/* Paragraph/Rubik 12pt/Right/White font style */
/* Caption/Rubik/Center/Gray font style */
/* Caption/Rubik/Left/Gray font style */
/*
    Utilities:
*/
/** primary **/
/** secondary **/
/** tertiary **/
/** extended primary blue **/
/** extended secondary red **/
/** extended secondary yellow **/
/** extended secondary green **/
/** extended  **/
/** extended  **/
/** FROM inv, should be replaced by the true colors names from Sergiu files **/
/** primary **/
.mat-primary-blue {
  background-color: #0066ff;
}

.mat-primary-dark {
  background-color: #262830;
}

.mat-primary-neutral {
  background-color: #474d66;
}

.mat-primary-light {
  background-color: #ffffff;
}

.mat-primary-blue-font {
  color: #0066ff;
}

.mat-primary-dark-font {
  color: #262830;
}

.mat-primary-neutral-font {
  color: #474d66;
}

.mat-primary-light-font {
  color: #ffffff;
}

.mat-primary-blue-border {
  border-color: #0066ff;
}

.mat-primary-dark-border {
  border-color: #262830;
}

.mat-primary-neutral-border {
  border-color: #474d66;
}

.mat-primary-light-border {
  border-color: #ffffff;
}

/** secondary **/
.mat-secondary-green {
  background-color: #52bd94;
}

.mat-secondary-yellow {
  background-color: #ffb020;
}

.mat-secondary-red {
  background-color: #d14343;
}

.mat-secondary-green-font {
  color: #52bd94;
}

.mat-secondary-yellow-font {
  color: #ffb020;
}

.mat-secondary-red-font {
  color: #d14343;
}

.mat-secondary-green-border {
  border-color: #52bd94;
}

.mat-secondary-yellow-border {
  border-color: #ffb020;
}

.mat-secondary-red-border {
  border-color: #d14343;
}

/** tertiary **/
.mat-tertiary-violet {
  background-color: #897ae3;
}

.mat-tertiary-teal {
  background-color: #25cbd6;
}

.mat-tertiary-pink {
  background-color: #ed55c2;
}

.mat-tertiary-orange {
  background-color: #de7548;
}

.mat-tertiary-violet-font {
  color: #897ae3;
}

.mat-tertiary-teal-font {
  color: #25cbd6;
}

.mat-tertiary-pink-font {
  color: #ed55c2;
}

.mat-tertiary-orange-font {
  color: #de7548;
}

.mat-tertiary-violet-border {
  border-color: #897ae3;
}

.mat-tertiary-teal-border {
  border-color: #25cbd6;
}

.mat-tertiary-pink-border {
  border-color: #ed55c2;
}

.mat-tertiary-orange-border {
  border-color: #de7548;
}

html {
  --scrollbarBG: #f0f0f0;
  --thumbBG: #cdcdcd;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar {
  width: 7px;
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar {
  width: 7px;
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.mat-autocomplete-panel {
  overflow-y: auto;
}
.mat-autocomplete-panel::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.mat-autocomplete-panel::-webkit-scrollbar-track {
  background-color: #d8dae5;
  box-shadow: inset 0 0 3px #d8dae5;
}
.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: #8f95b2;
  border-radius: 5px;
}

.box-shadow {
  box-shadow: 0px 3px 6px -5px #eff2ff, 0px 2px 12px 2px #d1d6ec;
}

.container-item {
  background-color: #fff;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 0 7px #dce0f5;
}
.container-item .container-title {
  font-size: 15px;
  line-height: normal;
  text-align: left;
  color: #2e384d;
  font-style: normal;
  font-weight: 500;
  font-family: Rubik;
}

.min-height-zero {
  min-height: 0;
}

openreel-hosting-share .mat-button-focus-overlay {
  display: none !important;
}

.vjs-viostream-chaptering-dropdown {
  background: #616161;
  width: 100%;
  font-size: 14px;
  color: white;
}

.text-highlight {
  color: #0066ff;
}