@import './colors.scss';

$icon-size: 48px;

.creator-alert {
  &.primary {
    .title-icon {
      background: radial-gradient(#ebf0ff 0, #ebf0ff 50%, #f3f6ff 50%, #f3f6ff 100%);

      svg {
        fill: $color-primary-blue;
      }
    }
  }

  &.green {
    .title-icon {
      background: radial-gradient(#d1fadf 0, #d1fadf 50%, #ecfdf3 50%, #ecfdf3 100%);
    }
  }

  &.warning {
    .title-icon {
      background: radial-gradient(#fadfd1 0, #fadfd1 50%, #fdf3ec 50%, #fdf3ec 100%);

      svg {
        fill: #ff9a00;
      }
    }
  }

  &.compact {
    .mat-dialog-actions {
      min-height: auto !important;
      margin-top: 20px;
      gap: 10px;

      button {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .mat-dialog-container {
    padding: 24px;
    position: relative;

    openreel-alert {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title-icon {
      border-radius: 50%;
      width: $icon-size;
      height: $icon-size;
      text-align: center;
      margin-bottom: 20px;

      svg {
        width: 24px;
      }
    }

    h2 {
      margin-bottom: 8px;
      height: 28px;
      line-height: 28px;
      font-weight: 600;
      font-size: 18px;
      color: #101828;
    }

    .mat-dialog-content {
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;
      overflow: auto;
      display: block;

      > .mat-icon {
        display: none;
      }

      .alert-message {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #667085;

        &.center {
          text-align: center;
          display: inline-block;
          width: 100%;
        }
      }
    }

    .mat-dialog-actions {
      padding: 0;
      margin-top: 32px;
      margin-bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      gap: 12px;

      button {
        flex-grow: 1;
        flex-basis: 0;
        margin: 0;
        padding: 8px;
        background-color: #ffffff;
        border: 1px solid $color-neutral-N400;
        box-shadow: none;
        font-weight: 400;

        &.mat-primary,
        &.mat-button-disabled.mat-primary {
          background-color: $color-primary-blue;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
}

.close-icon {
  top: 0px !important;
}
