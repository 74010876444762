
.or-pager .mat-select-arrow {
    margin-right: 10px;
    margin-top: 2px;
}

.or-pager .mat-select-value-text {
    padding-left: 10px;
    padding-top: 4px;
    display: block;
}

.or-pager .mat-select-trigger {
    background-color: white;
    border-radius: 5px;
    width: 50px;
    height: 30px;
    font-size: 12px;
}
