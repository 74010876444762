@use '@angular/material' as mat;

@import './colors.scss';
@import './palette.scss';

@include mat.core();
@include mat.legacy-core();

$default-app-primary: mat.define-palette($mat-primary-blue);
$default-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$default-app-warn: mat.define-palette($mat-secondary-red);

$secondary-app-primary: mat.define-palette($mat-secondary-green);
$secondary-app-accent: mat.define-palette($mat-secondary-yellow);
$secondary-app-warn: mat.define-palette($mat-secondary-red);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $default-app-primary,
      accent: $default-app-accent,
      warn: $default-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);

// dark theme
.dark-theme {
  color: white;
  $dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $default-app-accent,
        warn: $default-app-warn,
      ),
    )
  );
  @include mat.all-component-colors($dark-theme);
  @include mat.all-legacy-component-themes($dark-theme);
}

// openreel secondary theme
.openreel-secondary-theme {
  $secondary-theme: mat.define-light-theme(
    (
      color: (
        primary: $secondary-app-primary,
        accent: $secondary-app-accent,
        warn: $secondary-app-warn,
      ),
    )
  );
  @include mat.all-component-colors($secondary-theme);
  @include mat.all-legacy-component-themes($secondary-theme);
}

.color-warn {
  color: mat.get-color-from-palette($default-app-warn);
}

.white-bg-control .mat-form-field-outline {
  background-color: white !important;
}

.cdk-drag:not(.cdk-drag-disabled) {
  cursor: grab;
}

.cdk-drag-dragging {
  cursor: grabbing;
}
