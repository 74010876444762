sketch-picker {
  &.layer-color {
    width: 100%;
    box-shadow: unset;

    hue-component,
    alpha-component {
      height: 16px;
      border-radius: 4px;
    }

    .column.indicator-column {
      display: none;
    }
  }
}
