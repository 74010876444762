@import 'inv.scss';

.video-preview {
  &.dark-theme {
    background-color: $color-darker-blackish;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .buttons {
    margin-top: 19px;

    :not(:last-child) {
      margin-right: 9px;
    }

    .row {
      margin-bottom: 10px;
      display: flex;
    }
  }

  .button {
    width: 100%;
    height: 30px;
    font-size: 11px;
    color: white;
    font-weight: 500;
    background-color: #358dff;
    line-height: 30px;
    vertical-align: middle;
    padding: 0 5px;

    .icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      vertical-align: top;
    }

    &.alert {
      background-color: #d63649;
    }
  }

  .logo {
    width: 173px;
    height: 36px;
  }

  .content {
    padding: 39px 115px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    background-color: $color-darker-blackish;
  }

  .sidebar ~ .main-container {
    width: calc(100% - 310px);
  }

  .main-container {
    margin-left: auto;
    margin-right: auto;
    padding: 22px;
    border-radius: 5px;
    background-color: $color-dark-blackish;
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-bottom: none;

    .title {
      color: #69707f;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 19px;
      font-weight: 300;
    }

    .subtitle {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: #b0bac9;
      margin-bottom: 28px;

      &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #b0bac9;
        margin-left: 14px;
      }
    }

    .preview {
      margin: 0 auto;
      width: 1080px;
      display: block;
      max-width: 100%;
    }
  }

  .sidebar {
    width: 280px;

    .container {
      background-color: $color-dark-blackish;
      color: white;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      border-bottom: none;
      width: 235px;
      padding: 22px;
      border-radius: 5px;
    }

    .title {
      color: #8798ad;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 15px;
      font-weight: 300;
      margin-bottom: 26px;
    }
  }
}

// for mobile screens
@media only screen and (max-width: 1024px) {
  .video-preview .content {
    padding: 39px 15px;
    flex-direction: column;
  }

  .video-preview .sidebar ~ .main-container {
    width: calc(100% - 100px);
  }

  .video-preview .sidebar .container {
    width: 100% !important;
  }

  .video-preview .sidebar {
    width: calc(100% - 100px) !important;
    padding-left: 22px;
  }
  
  .video-preview .sidebar .preview {
    width: 100% !important;
  }

  .video-preview .main-container .preview {
    width: 100% !important;
  }
}
